<template>
  <div class="residence_box">
    <div
      class="avatar"
      :style="{
        backgroundImage: `url(${item.main_image || placeholder_image})`,
      }"
    ></div>
    <div class="description">
      <p v-text="item.accommodation_type"></p>
      <p v-text="item.name"></p>
      <p>
        <img src="@/assets/images/ui/location_orange.svg" alt="" />{{
          item.place_name
        }}
      </p>
      <p v-html="item.description"></p>
      <p v-if="item.feed_name">
        <img src="@/assets/images/ui/dinner.svg" alt="" /> {{ item.feed_name }}
      </p>
      <p v-if="item.has_transfer">
        <img src="@/assets/images/ui/parking.svg" alt="" />Есть трансфер
      </p>
    </div>
    <div class="actions">
      <div class="actions__rating">
        <div>
          <p>Отлично!</p>
          <p>123 отзывов</p>
        </div>
        <div>
          <v-icon size="24" color="#FFC738">mdi-star</v-icon>
          <p v-text="item.rating"></p>
        </div>
      </div>
      <div class="actions__price">
        <p>
          {{
            !item.is_work
              ? "Не работает"
              : !item.has_room
              ? "Нет мест"
              : `от ${item.min_price.toLocaleString("KZ-kz")} KZT`
          }}
        </p>
        <v-btn
          width="155"
          height="44"
          color="primary"
          @click="$emit('goPage', item)"
        >
          ПОДРОБНЕЕ
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  data: () => ({
    placeholder_image: require("@/assets/images/test/residence.png"),
  }),
};
</script>

<style lang="scss" scoped>
.residence_box {
  height: 192px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 159px 1fr 155px;
  align-items: start;
  justify-content: space-between;
  align-items: start;
  .avatar {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }
  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    p {
      margin-bottom: 0;
      &:nth-of-type(1) {
        font-size: 12px;
        line-height: 14px;
        color: rgba(2, 16, 17, 0.59);
      }
      &:nth-of-type(2) {
        margin-top: 6px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #021011;
      }
      &:nth-of-type(3),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        display: grid;
        grid-template-columns: 12px 1fr;
        grid-column-gap: 6px;
        align-items: center;
        font-size: 12px;
        line-height: 14px;
        color: #021011;
        margin-top: 6px;
      }
      &:nth-of-type(4) {
        font-size: 12px;
        line-height: 14px;
        color: #021011;
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 6px;
        height: 42px;
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    p {
      margin-bottom: 0;
    }
    &__rating {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      justify-content: space-between;
      width: 100%;
      div {
        &:first-of-type {
          p {
            &:first-of-type {
              font-size: 14px;
              line-height: 16px;
              color: #336cc0;
            }
            &:last-of-type {
              font-size: 12px;
              line-height: 14px;
              color: rgba(2, 16, 17, 0.5);
            }
          }
        }
        &:last-of-type {
          display: grid;
          grid-template-columns: 24px 1fr;
          grid-column-gap: 1px;
          align-items: center;
          p {
            font-size: 24px;
            line-height: 28px;
            color: #021011;
          }
        }
      }
    }
    &__price {
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #021011;
        margin-bottom: 12px;
      }
    }
  }
}
</style>
