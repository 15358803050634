<template>
  <div>
    <div class="search_bar">
      <v-select
        v-model="filter.city"
        :items="cities"
        placeholder="Курорт"
        prepend-inner-icon="mdi-map-marker"
        height="48"
        item-value="slug"
        item-text="name"
        hide-details
        outlined
        dense
        @change="getPlaces"
      ></v-select>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="filter.dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            placeholder="Заезд - выезд"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            height="48"
            v-on="on"
            hide-details
            outlined
            dense
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="filter.dates"
          :first-day-of-week="1"
          no-title
          range
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Отмена </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(filter.dates)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <people-count :model="filter" />
      <v-text-field
        v-model="filter.room_count"
        hide-details
        outlined
        dense
        height="48"
        placeholder="Количество номеров"
        prepend-inner-icon="mdi-bed-queen"
      ></v-text-field>
      <v-btn @click="find" color="primary" height="48" width="160">
        Найти
      </v-btn>
    </div>
    <near-dates
      v-if="counter > 0"
      :nearDates="near_list"
      @onClick="handleChangeNear"
    />
    <div class="page_content">
      <div class="residence_filter" v-if="counter > 0">
        <p>Найти</p>
        <v-text-field
          placeholder="Название отеля"
          height="36"
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details
          outlined
          class="mb-3"
          v-model="filter.search"
        >
        </v-text-field>
        <p v-if="places.length > 0">По местоположению</p>
        <v-checkbox
          v-model="filter.places"
          v-for="item in places"
          :key="item.name"
          :label="item.name"
          :value="item.id"
          hide-details
          dense
        >
        </v-checkbox>
        <v-divider class="my-3" />
        <p>По размещению</p>
        <v-checkbox
          v-model="filter.accommodation_types"
          v-for="item in typeFilter"
          :key="item.value"
          :label="item.text"
          :value="item.value"
          hide-details
          dense
        >
        </v-checkbox>
        <v-divider class="my-3" />
        <p>По бюджету</p>
        <v-range-slider
          v-model="filter.price_range"
          max="10000000"
          min="0"
          hide-details
        ></v-range-slider>
        <div class="range_models">
          <div>
            <p>от:</p>
            <v-text-field
              dense
              outlined
              hide-details
              v-model="filter.price_range[0]"
              height="36"
            >
            </v-text-field>
          </div>
          <div>
            <p>до:</p>
            <v-text-field
              dense
              outlined
              hide-details
              v-model="filter.price_range[1]"
              height="36"
            >
            </v-text-field>
          </div>
        </div>
        <v-divider class="my-3" />
        <p>По рейтингу</p>
        <v-checkbox
          v-model="filter.rating"
          v-for="(item, index) in ratingItems"
          :key="item.id"
          :value="item.id"
          hide-details
        >
          <template #label>
            <v-icon
              size="20"
              v-for="(star, idx) in index + 1"
              :key="idx"
              color="#FFC738"
              >mdi-star</v-icon
            >
          </template>
        </v-checkbox>
        <v-divider class="my-3" />
        <p v-if="foodFilter.length > 0">По питанию</p>
        <v-checkbox
          v-model="filter.feeds"
          v-for="item in foodFilter"
          :key="item.name"
          :value="item.id"
          :label="item.name"
          hide-details
          dense
        >
        </v-checkbox>
        <div class="text-center mt-6">
          <v-btn class="mb-3" height="36" outlined color="accent" @click="find">
            применить фильтр
          </v-btn>
          <v-btn height="36" @click="resetFilter" outlined color="primary">
            сбросить фильтр
          </v-btn>
        </div>
      </div>
      <div>
        <div class="sort_by" v-if="counter > 0">
          <p>Сортировать по</p>
          <v-select
            :items="sortList"
            v-model="filter.order_by"
            height="44"
            hide-details
            outlined
            dense
            @change="find"
          ></v-select>
        </div>
        <residence-box
          v-for="item in categoies"
          :key="item.slug"
          :item="item"
          @goPage="goPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import peopleCount from "@/components/ui/peopleCount.vue";
import NearDates from "@/components/ui/nearDates.vue";
import ResidenceBox from "@/components/booking/residence/residenceBox.vue";
import InputContent from "../../../components/inputs/inputContent.vue";
import { accommodationService } from "@/services/accommodation.service.js";
export default {
  components: {
    peopleCount,
    NearDates,
    ResidenceBox,
    InputContent,
  },
  data: () => ({
    typeFilter: [
      {
        value: "rest_zone",
        text: "Зона отдыха",
      },
      {
        value: "hotel",
        text: "Отель",
      },
      {
        value: "guest_house",
        text: "Гостевой дом",
      },
    ],
    foodFilter: [],
    ratingItems: [
      {
        name: "Star",
        id: 1,
      },
      {
        name: "Отель",
        id: 2,
      },
      {
        name: "Гостевой дом",
        id: 3,
      },
      {
        name: "СанаториЙ",
        id: 4,
      },
      {
        name: "СанаториЙ",
        id: 5,
      },
    ],
    filter: {
      adult: 1,
      child: 0,
      city: null,
      dates: [],
      order_by: "-popularity",
      price_range: [0, 10000000],
      accommodation_types: [],
      rating: [],
      feeds: [],
      places: [],
      room_count: 1,
    },
    sortList: [
      {
        text: "Популярности",
        value: "-popularity",
      },
      {
        text: "Уменьшение рейтинга",
        value: "-rating",
      },
      {
        text: "Увеличение рейтинга",
        value: "rating",
      },
      {
        text: "Убывание цены",
        value: "-min_price",
      },
      {
        text: "Возрастание цены",
        value: "min_price",
      },
    ],
    cities: [],
    menu: false,
    near_list: [],
    categoies: [],
    rating: [],
    places: [],
    counter: 0,
  }),
  computed: {
    dateRangeText() {
      if (this.filter.dates.length == 2) {
        if (this.filter.dates[0] > this.filter.dates[1])
          this.filter.dates.reverse();
        return this.filter.dates.join(" ~ ");
      }
      return "";
    },
  },
  watch: {
    "$route.name"() {
      if (this.$route.query.start) {
        this.setFilter();
        this.find();
      }
      if (this.$route.query.city) this.getPlaces();
      this.getCities();
      this.getFeed();
    },
  },
  created() {
    if (this.$route.query.start) {
      this.setFilter();
      this.find();
    }
    if (this.$route.query.city) this.getPlaces();
    this.getCities();
    this.getFeed();
  },
  methods: {
    resetFilter() {
      this.filter = {
        ...this.filter,
        order_by: "-popularity",
        price_range: [0, 10000000],
        accommodation_types: [],
        rating: [],
        feeds: [],
        places: [],
        room_count: 1,
      };
      this.find();
    },
    setFilter() {
      this.filter = {
        ...this.filter,
        ...this.$route.query,
        dates: [this.$route.query.start, this.$route.query.end],
        adult: +this.$route.query.adult,
        child: +this.$route.query.child,
      };
      if (this.filter.rating && typeof this.filter.rating == "object") {
        this.filter.rating = this.filter.rating.map((e) => +e);
      } else if (this.filter.rating && typeof this.filter.rating == "string") {
        this.filter.rating = [+this.filter.rating];
      }
      if (
        this.filter.accommodation_types &&
        typeof this.filter.accommodation_types == "string"
      ) {
        this.filter.accommodation_types = [this.filter.accommodation_types];
      }
      if (this.filter.feeds && typeof this.filter.feeds == "string") {
        this.filter.feeds = [+this.filter.feeds];
      } else if (this.filter.feeds && typeof this.filter.feeds == "object") {
        this.filter.feeds = this.filter.feeds.map((e) => +e);
      }
      if (this.filter.places && typeof this.filter.places == "string") {
        this.filter.places = [+this.filter.places];
      } else if (this.filter.places && typeof this.filter.places == "object") {
        this.filter.places = this.filter.places.map((e) => +e);
      }
      if (
        this.filter.price_range &&
        typeof this.filter.price_range == "string"
      ) {
        this.filter.price_range = this.filter.price_range.split(",");
        this.filter.price_range = this.filter.price_range.map((e) => +e);
      }
    },
    async getPlaces() {
      let res = await accommodationService.getSubPlaces(this.filter.city);
      this.places = res;
    },
    async getFeed() {
      let res = await accommodationService.getFeed();
      this.foodFilter = res;
    },
    async getCities() {
      let res = await accommodationService.getCities();
      this.cities = res;
    },
    async getNearest() {
      let params = {
        start: this.filter.dates[0],
        end: this.filter.dates[1],
        city: this.filter.city,
        adult_count: this.filter.adult,
        child_count: this.filter.child,
        order_by: this.filter.order_by,
        search: this.filter.search,
        accommodation_types:
          typeof this.filter.accommodation_types == "object"
            ? this.filter.accommodation_types.join(",")
            : this.filter.accommodation_types,
        places:
          typeof this.filter.places == "object"
            ? this.filter.places.join(",")
            : this.filter.places,
        rating:
          typeof this.filter.rating == "object"
            ? this.filter.rating.join(",")
            : this.filter.rating,
        room_count: this.filter.room_count,
        feeds:
          typeof this.filter.feeds == "object"
            ? this.filter.feeds.join(",")
            : this.filter.feeds,
        price_range:
          typeof this.filter.price_range == "object"
            ? this.filter.price_range.join(",")
            : this.filter.price_range,
      };
      let res = await accommodationService.getNearest(params);
      res.forEach((element) => {
        element.price = element.min_price;
        element.is_selected = element.selected;
      });
      this.near_list = res;
    },
    async getCompanies() {
      let params = {
        start: this.filter.dates[0],
        end: this.filter.dates[1],
        city: this.filter.city,
        adult_count: this.filter.adult,
        child_count: this.filter.child,
        order_by: this.filter.order_by,
        search: this.filter.search,
        accommodation_types:
          typeof this.filter.accommodation_types == "object"
            ? this.filter.accommodation_types.join(",")
            : this.filter.accommodation_types,
        places:
          typeof this.filter.places == "object"
            ? this.filter.places.join(",")
            : this.filter.places,
        rating:
          typeof this.filter.rating == "object"
            ? this.filter.rating.join(",")
            : this.filter.rating,
        room_count: this.filter.room_count,
        feeds:
          typeof this.filter.feeds == "object"
            ? this.filter.feeds.join(",")
            : this.filter.feeds,
        price_range:
          typeof this.filter.price_range == "object"
            ? this.filter.price_range.join(",")
            : this.filter.price_range,
      };
      let query = JSON.parse(JSON.stringify(this.filter));
      query.start = query.dates[0];
      query.end = query.dates[1];
      delete query.dates;
      this.$router.push({ name: this.$route.name, query: { ...query } });
      let res = await accommodationService.getCompanies(params);
      this.categoies = res.results;
      this.counter++;
    },
    find() {
      let message = null;
      if (this.filter.dates.length < 2 || !this.filter.city)
        message = "Выберите все поля";
      else if (this.filter.room_count == 0) message = "Не может быть 0 комнат!";
      if (message) {
        this.$store.commit("ui/SET_NOTIFICATION", {
          show: true,
          message,
          color: "error",
        });
        return;
      }
      this.$loading(true);
      this.getCompanies();
      this.getNearest();
      this.$loading(false);
    },
    handleChangeNear(value) {
      let date = this.$moment(value.near_date * 1000).format("YYYY-MM-DD");
      let new_date = this.$moment(date).add(1, "days").format("YYYY-MM-DD");
      this.filter.dates = [date, new_date];
      this.find();
    },
    goPage(item) {
      let query = {
        ...this.filter,
        start: this.filter.dates[0],
        end: this.filter.dates[1],
      };
      delete query.dates;
      this.$router.push({
        path: `/booking/residence/${item.slug}/`,
        query: query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search_bar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 160px;
  align-items: center;
}
.page_content {
  padding-top: 16px;
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: start;
  grid-column-gap: 16px;
  .residence_filter {
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.1);
    padding: 18px;
    width: 250px;
    border-radius: 5px;
    p {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #021011;
    }
  }
}
.sort_by {
  display: grid;
  grid-template-columns: 123px 214px;
  grid-column-gap: 20px;
  align-items: center;
  justify-content: end;
  margin-bottom: 16px;
  p {
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #021011;
    margin-bottom: 0;
  }
}
.range_models {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
</style>
